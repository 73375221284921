import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  Operation,
  Observable,
  FetchResult,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

const isProduction = import.meta.env.PROD

const httpLink = new HttpLink({
  uri: isProduction ? 'https://api-lb.eclass.com/graphql' : '/graphql',
})

// Función para realizar reintentos
function retryOperation(
  operation: Operation,
  forward: any,
  maxRetries: number,
  delayMs: number,
): Observable<FetchResult> {
  let attempt = 0

  return new Observable((observer) => {
    const retry = () => {
      forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: (err) => {
          attempt++
          if (attempt <= maxRetries) {
            // Reintentando conexión
            setTimeout(retry, delayMs) // Espera antes de reintentar
          } else {
            console.error(`Operación fallida tras ${maxRetries} intentos de consultar API v8:`, err)
            // observer.error(err) // Propaga el error después de agotar los intentos
            window.location.href = '/Oops'
          }
        },
        complete: observer.complete.bind(observer),
      })
    }

    retry() // Primera ejecución
  })
}

// Error link para manejar errores y reintentar la operación
const errorLink = onError(({ networkError, operation, forward }) => {
  if (networkError) {
    // entra al flujo de reintentos
    return retryOperation(operation, forward, 5, 2000) // 5 reintentos, 2 segundos entre cada intento
  }
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      origin: 'https://campus.eclass.com',
    },
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

export default client
